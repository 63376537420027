import { DialogType } from "../..";
import BaseDialogCtrl from "../../base/controller";

export default class ChooseAlbumDialogCtrl extends BaseDialogCtrl {
  _type = DialogType.ChooseAlbum;

  /**
   * @type {string[]}
   */
  seasonList;

  /**
   * アルバム選択時に実行するコールバック
   * @type {(season: string) => void}
   */
  onChooseAlbum;

  /**
   * @param {number[]} seasonList 
   * @param {(season: string) => void} onChooseAlbum 
   */
  constructor(seasonList, onChooseAlbum) {
    super();
    this.seasonList = seasonList.map(season => String(season));
    this.onChooseAlbum = onChooseAlbum;
  }
}
